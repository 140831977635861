import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    ColorField, CustomTypeModelSelectField,
    FilledLinkToWebField,
    ImageField,
    KeyTextField,
    PrismicDocument,
    RichTextField
} from '@prismicio/client';
import { LinkField, SelectField } from '@prismicio/types';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

    private static TYPE: 'tenant' = 'tenant';

    getTenant(uid: string): Observable<PrismicDocument<Tenant>> {
        return from(environment.prismic.client.getByUID<PrismicDocument<Tenant>>(TenantService.TYPE, uid));
    }
}

export type  Tenant = {
    page_title: string;
    meta_description: string;
    name: KeyTextField;
    color: ColorField;
    logo: ImageField;
    title: RichTextField;
    text: RichTextField;
    imprint: LinkField;
    data_policy: LinkField;
    personal_data_title: RichTextField;
    personal_data_description: RichTextField;
    organization_type: SelectField;
    contact_person: SelectField;
};
